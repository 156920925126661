export default{
    title: 'light',

    colors:{
        primary: '#712831',
        secundary: '#636466',

        background: '#fff',
        text: '#636466',
        buttonText: '#fff',

        shadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',

        backgroundpresentation: '#fff',
        backgroundpresentation780: '#fff',
    }
}