const TechlogiesData = [
    { "id": 1, "name": "Android" },
    { "id": 2, "name": "Kubernetes" },
    { "id": 3, "name": "Docker" },
    { "id": 4, "name": "Prometheus" },
    { "id": 5, "name": "Nginx" },
    { "id": 6, "name": "AWS" },
    { "id": 7, "name": "Git" },
    { "id": 8, "name": "Linux" },
    { "id": 9, "name": "MySQL" },
    { "id": 10, "name": "MongoDB" },
    { "id": 11, "name": "Terraform" },
    { "id": 12, "name": "Ansible" },
    { "id": 13, "name": "Angular" },
    { "id": 14, "name": "Grafana" },
    { "id": 15, "name": "Google Cloud" },
    { "id": 16, "name": "Argo" }
];

export default TechlogiesData;