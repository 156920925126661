import React, { useContext } from 'react';
import Switch from 'react-switch';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { ContainerAbout, Image, SubContainerAbout, SubTitle, Description, ContainerButtons, ContactButton, TextButton } from './styles';

import photo from '../../assets/photo.png';

import { FiArrowRight } from "react-icons/fi";
interface Props {
    toggleTheme(): void;
}

const About: React.FC<Props> = ({ toggleTheme }) => {
    const { colors, title } = useContext(ThemeContext);

    return (
        <ContainerAbout id="sobre">
            <Image src={photo} alt="Gabriel Sousa" />

            <SubContainerAbout>
                <SubTitle>
                    Sobre mim
                </SubTitle>

                <Description>
                Em 2017-2018, explorei possibilidades e descobri minha paixão pela programação ao criar o "Radarzinho" em JavaScript. Essa experiência foi divisa para que após 2019 com o termino do ensino medio focar em evoluí minhas habilidades e passei a um ano depois já estava a desenvolver meus próprios exploits.
                </Description>
                <Description>
                Atualmente, estou me aventurando em algumas tecnologias incríveis! Estou explorando Android, AWS, Docker, Git, Linux, MySQL, MongoDB, Terraform, Ansible, Angular, Grafana, Google Cloud e Argo. E diariamente tenho novas descoberta!
                </Description>
                <Description>
                Meu objetivo é sempre expandir meus conhecimentos e aprimorar as habilidades que já tenho. Estou constantemente buscando aprendizado e evolução diária para agregar ainda mais valor ao meu trabalho.</Description>
                <ContainerButtons>
                    <ContactButton href="#contato">
                        <TextButton>
                            Contato
                        </TextButton>
                    </ContactButton>
                </ContainerButtons>
            </SubContainerAbout>
        </ContainerAbout>
    );
}

export default About;